<template >
  <div id="subscribe">
    <div class="header">
      <div class="close"><span @click="closeModal()">x</span></div>
      <div v-if="!isSend" class="containt-subscribe">
        <Icon class="icon" name="subscriptions" height="90px" width="90px"></Icon>
        <div class="title">Inscrivez-vous à notre Newsletter</div>
        <p class="containt">
          Recevez les coups de coeur de Marco Vasco ! <br />
          Des inspirations de séjours et des reportages de nos créateurs de voyages...
        </p>
      </div>
      <p v-else class="containt">
        Merci de vous être inscrit à notre newsletter ! Vous pourrez à tout moment vous désinscrire
        via un lien de désinscription figurant en bas de chaque envoi. <br />
        <br />En attendant de recevoir nos bons plans et newsletters, nous avons le plaisir de vous
        offrir notre guide de voyage
        <NuxtLink class="link" :to="`${strapiURL}${urlPdf}`">
          « 12 mois de voyage avec MARCO VASCO » </NuxtLink
        >(34Mo) à télécharger, réalisé avec le Lonely Planet.<br /><br />
        Bonne lecture !
      </p>
    </div>
    <form v-if="!isSend" class="form" @submit.prevent="handelSubscribe()">
      <div class="form-radio">
        <div class="form-radio-gender">
          <label>
            <input v-model="gender" type="radio" name="gender" value="female" />
            Madame
          </label>
          <label>
            <input v-model="gender" type="radio" name="gender" value="male" />
            Monsieur
          </label>
        </div>
        <div v-show="errors.gender.isRequired.isError" class="errors-container">
          <span class="error"> {{ errors.gender.isRequired.text }}</span>
        </div>
      </div>
      <div class="form-name">
        <InputWrapper
          v-model="firstName"
          class="input"
          type="text"
          placeholder="Prénom*"
          :errors="[
            errors.firstName.isRequired.isError
              ? {
                  text: errors.firstName.isRequired.text,
                  value: errors.firstName.isRequired.isError
                }
              : undefined
          ]"
        />
        <InputWrapper
          v-model="lastName"
          class="input"
          type="text"
          placeholder="Nom*"
          :errors="[
            errors.lastName.isRequired.isError
              ? {
                  text: errors.lastName.isRequired.text,
                  value: errors.lastName.isRequired.isError
                }
              : undefined
          ]"
        />
      </div>
      <div class="form-email">
        <InputWrapper
          v-model="email"
          class="input"
          type="email"
          placeholder="Entrez votre email*"
          :errors="[
            errors.email.isRequired.isError
              ? {
                  text: errors.email.isRequired.text,
                  value: errors.email.isRequired.isError
                }
              : undefined,
            errors.email.isRightFormat.isError
              ? {
                  text: errors.email.isRightFormat.text,
                  value: errors.email.isRightFormat.isError
                }
              : undefined
          ]"
        />
      </div>
    </form>
    <div class="form-action">
      <button v-if="!isSend" @click="handelSubscribe()">S'inscrire</button>
      <button v-else @click="closeModal()">Fermer la fenếtre</button>
    </div>
    <div v-if="!isSend" class="cnil-wrapper">
      <LegalCNIL class="cnil"></LegalCNIL>
    </div>
  </div>
</template>
<script lang="ts" setup>
const { setEmail = '' } = defineProps<{
  closeModal: () => void;
  setEmail?: string;
}>();
const lastName = ref<string>('');
const firstName = ref<string>('');
const email = ref<string>(setEmail);
const gender = ref<string>('');
const isSend = ref<boolean>(false);
const { validateField, errors } = useValidateErrors();

const { subscribe, success } = useNewsletter();
const strapiURL = useRuntimeConfig().public.strapi.url;
const urlPdf = '/uploads/Lonely_Planet_X_MARCO_VASCO_2022_1a73e23db6.pdf';

const isCanSubscribe = $computed(() => {
  if (
    !errors.firstName.isRequired.isError &&
    !errors.firstName.firstRenderError &&
    !errors.lastName.isRequired.isError &&
    !errors.lastName.firstRenderError &&
    !errors.email.isRequired.isError &&
    !errors.email.firstRenderError &&
    !errors.email.isRightFormat.isError &&
    !errors.gender.firstRenderError &&
    !errors.gender.isRequired.isError
  ) {
    return true;
  }
  return false;
});

function resetFormSubscirbe() {
  lastName.value = '';
  firstName.value = '';
  gender.value = '';
  email.value = '';
}

async function handelSubscribe() {
  validateField('lastName', lastName.value);
  validateField('firstName', firstName.value);
  validateField('gender', gender.value);
  validateField('email', email.value);

  if (isCanSubscribe) {
    await subscribe({
      brand: 'mv',
      mail: email.value,
      title: gender.value,
      firstName: firstName.value,
      lastName: lastName.value,
      frequency: 'weekly'
    });

    isSend.value = success.value;

    success.value && resetFormSubscirbe();
  }
}
</script>
<style lang="scss" scoped>
@use '$/button.scss';
@use '$/colors.scss';
@use '$/breakpoints.scss';
@use '$/input.scss';

#subscribe {
  position: relative;

  overflow-x: hidden;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  overflow-y: scroll;

  max-width: 600px;
  max-height: 650px;
  margin: 50px;
  padding: 10px 50px;

  background-color: white;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .close {
    display: flex;
    justify-content: flex-end;

    width: 100%;
    margin-top: 20px;

    font-size: 1.4em;
    font-weight: 500;

    @include breakpoints.mobile() {
      width: 85%;
    }

    span {
      cursor: pointer;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    @include breakpoints.mobile() {
      width: 80%;
    }

    .form-radio {
      display: flex;
      flex-direction: column;

      .errors-container {
        flex-direction: column;

        .error {
          @extend %error-label;
        }
      }

      .form-radio-gender {
        gap: 30px;
        font-size: 1.2em;
      }
    }

    .input {
      margin: 20px 0;

      :deep(input) {
        color: black;
        border-bottom: solid 1px black;
      }
    }

    .form-name {
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;

      .input {
        flex: 1;
        width: 100%;
        min-width: 200px;
      }
    }
  }

  .header {
    .title {
      font-size: 1.9em;
      font-weight: 700;
      text-align: center;
    }

    .icon {
      margin-top: -20px;

      :deep(.cls-1) {
        fill: colors.$primary-yellow;
        stroke: colors.$primary-yellow;
        stroke-width: 9;
      }
    }

    .containt {
      margin: 20px;
      font-size: 1.2em;
      text-align: center;
      text-wrap: wrap;

      .link {
        color: colors.$primary-red;
      }
    }
  }
}

.cnil-wrapper {
  align-items: flex-end;

  .cnil {
    max-width: 600px;
    margin: -5px;
    padding: 10px 20px;
    font-size: 1em;
  }
}

.form-action {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;

  width: 100%;

  font-size: 0.8em;

  button {
    @include button.plain($color: colors.$primary-red, $size: 'big');

    @include breakpoints.mobile() {
      margin: auto;
    }
  }
}

.header,
#subscribe,
.containt-subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>