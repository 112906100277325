<template>
  <div v-if="common?.newsletter" class="newsletter">
    <div class="top">
      <NuxtImg
        v-if="common?.newsletter?.icon?.data"
        provider="twicpics"
        :src="common.newsletter.icon.data.attributes.url"
        class="icon"
      />
      <div class="header">
        <div class="title">{{ common.newsletter.title }}</div>
        <div class="sub-title">{{ common.newsletter.subtitle }}</div>
      </div>
    </div>

    <InputNewsletter placeholder-text="email@adresse.com" />
  </div>
</template>

<script lang="ts" setup>
const rootStore = useRootStore();
const { common } = storeToRefs(rootStore);
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/metrics.scss';
@use '$/mouse.scss';
@use '$/shadows.scss';

.newsletter {
  flex-direction: column;
  gap: 56px;
  padding: 60px 200px;
  background-color: colors.$grey-100;

  @include breakpoints.mobile() {
    gap: 25px;
    padding: 32px metrics.$mobile-inner-margin;
  }

  .top {
    gap: 20px;
    align-items: center;

    @include breakpoints.mobile() {
      gap: 0;
    }

    .icon {
      width: 60px;
      height: 60px;
    }

    .header {
      flex-direction: column;

      @include breakpoints.mobile() {
        align-items: flex-start;
      }

      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;

        @include breakpoints.mobile() {
          justify-content: center;
          font-size: 20px;
          line-height: 24px;
        }
      }

      .sub-title {
        font-size: 20px;
        line-height: 24px;
        color: colors.$grey-900;

        @include breakpoints.mobile() {
          justify-content: center;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
}
</style>
