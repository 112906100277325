<template>
  <form class="input-container" @submit.prevent="send">
    <input v-model="email" class="input" type="text" :placeholder="placeholderText" />
    <button class="send">
      <Icon class="icon" name="arrow-right" />
    </button>
    <Modal v-if="isDisplaySubscribe" v-model="isDisplaySubscribe">
      <template #default="{ closeModal }">
        <Subscribe :close-modal="closeModal" :set-email="email" /> </template
    ></Modal>
  </form>
</template>

<script lang="ts" setup>
defineProps<{
  placeholderText: string;
}>();
const isDisplaySubscribe = ref<boolean>(false);
const email = ref<string>('');

function send(): void {
  isDisplaySubscribe.value = true;
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/metrics.scss';
@use '$/mouse.scss';
@use '$/shadows.scss';

.input-container {
  $height: 72px;
  $mobile-height: 56px;

  position: relative;

  align-items: center;

  height: $height;

  background-color: white;
  border-radius: math.div($height, 2);
  box-shadow: shadows.$page;

  @include breakpoints.mobile() {
    height: $mobile-height;
    border-radius: math.div($mobile-height, 2);
  }

  .input {
    flex-grow: 1;

    height: 100%;
    padding-right: $height + 12px;
    padding-left: 32px;

    font-size: 18px;
    line-height: 22px;

    @include breakpoints.mobile() {
      padding-right: $mobile-height + 12px;
      font-size: 16px;
      line-height: 24px;
    }

    &::placeholder {
      color: colors.$grey-700;
      opacity: 1;
    }
  }

  .send {
    $padding: 8px;
    $size: $height - 2 * $padding;

    @include mouse.clickable-lightness(background-color, colors.$primary-black);

    position: absolute;
    top: $padding;
    right: $padding;

    display: flex;
    align-items: center;
    justify-content: center;

    width: $size;
    height: $size;

    border-radius: 50%;

    @include breakpoints.mobile() {
      $size: $mobile-height - 2 * $padding;

      width: $size;
      height: $size;

      .icon {
        width: 18px;
        height: 13px;
      }
    }
  }
}
</style>
